const $ = require('jquery');

$(document).ready(function() {
    // executes when HTML-Document is loaded and DOM is read
    // breakpoint and up
    $( "#unmute" ).click(function() {
        document.getElementById('vid').muted = !document.getElementById('vid').muted;
    });

    $("#closemodal").click(function() {
        $("#jeugdkampmodal").modal('hide');
    });

    $(window).resize(function(){
        if ($(window).width() >= 980){

            // when you hover a toggle show its dropdown menu
            $(".navbar .dropdown-toggle").hover(function () {
                $(this).parent().toggleClass("show");
                $(this).parent().find(".dropdown-menu").toggleClass("show");
            });

            // hide the menu when the mouse leaves the dropdown
            $( ".navbar .dropdown-menu" ).mouseleave(function() {
                $(this).removeClass("show");
            });

            // do something here
        }
    });

    if($('#jeugdkampmodal').length) {
        $("#jeugdkampmodal").modal('show');
    }

    var rows = document.getElementsByClassName("game-info");
    for (var i = 0; i <= rows.length; i++) {
        var widerScreenWidth = window.matchMedia("(min-width: 501px)");
        if (widerScreenWidth.matches) {
            rows[i].addEventListener("mouseover", toggleGameDetails)
            rows[i].addEventListener("mouseout", toggleGameDetails)
        } else {
            rows[i].addEventListener("click", toggleGameDetails)
        }
    }
});

var toggleGameDetails = (event) => {
    var value = event.target.parentElement.attributes['data-gameid'].value;
    var elements = document.getElementsByClassName("game-details");
    for (var i = 0; i <= elements.length; i++) {
        if (elements[i].attributes['data-gameid'].value === value) {
            elements[i].style.display = elements[i].style.display === 'contents' ? 'none' : 'contents';
        }
    }
};
